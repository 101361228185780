<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-subheader>
        견적서 관리
      </v-subheader>
      <v-sheet class="px-3 pb-0 d-flex" />
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(col, i) in cols"
                :id="'product-' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="pagination && pagination.list && pagination.list.length > 0">
            <tr v-for="(item, i) in pagination.list" :key="i">
              <td class="text-center text-truncate" @click="goToPage(item.idx)">
                <span class="text-truncate">
                  {{ item.idx }}
                </span>
              </td>
              <td class="text-center text-truncate" @click="goToPage(item.idx)">
                <span class="text-truncate">
                  {{ item.company ? item.company.companyName : '정보없음' }}
                </span>
              </td>
              <td class="text-center text-truncate" @click="goToPage(item.idx)">
                <span class="text-truncate">
                  {{ item.productName }}
                </span>
              </td>
              <td class="text-center text-truncate" @click="goToPage(item.idx)">
                <span class="text-truncate">
                  {{ item.manager }}
                </span>
              </td>
              <td class="text-center text-truncate" @click="goToPage(item.idx)">
                <span class="text-truncate">
                  {{ item.registerDate ? $parseDate(item.registerDate) : '-' }}
                </span>
              </td>
              <td class="text-center text-truncate">
                <v-btn
                  icon
                  @click="deleteEstimate(item)"
                >
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center text-body-2">
                조회된 견적서가 존재하지 않습니다.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet width="100%" class="pb-15 pb-md-3 mt-3">
        <v-row>
          <v-col cols="12" offset-md="2" md="8">
            <Pagination ref="pagination" :pagination="pagination" base-url="/manage/estimate/" :query="$route.query" />
          </v-col>
          <v-col cols="12" md="2" class="text-right pt-0 pt-md-3">
            <v-dialog
              v-model="showRegister"
              persistent
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
              max-width="100%"
              width="400"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="mb-1"
                  width="100%"
                  v-bind="attrs"
                  v-on="on"
                >
                  견적등록
                </v-btn>
              </template>
              <ManageEstimateRegister :companies="companies" @close-dialog="closeDialog" />
            </v-dialog>
            <v-btn
              width="100%"
              @click="exportToExcel"
            >
              EXPORT
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import Pagination from '@/components/core/Pagination'
  import ManageEstimateRegister from '@/views/manage/estimate/Register'
  export default {
    name: 'ManageEstimateContent',
    components: { ManageEstimateRegister, Pagination },
    props: {
      pagination: {},
      companies: {},
    },
    data () {
      return {
        cols: ['Index', 'Company', 'Product', 'Manager', 'Date', 'Del'],
        isLoading: false,
        selected: [],
        showRegister: false,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      goToPage (index) {
        const path = `/manage/estimate/detail/${index}`
        this.$router.push(path)
      },
      async deleteEstimate (estimate) {
        if (confirm('견적서를 삭제하시겠습니까?')) {
          const url = '/manage/estimate'
          await this.$axios.delete(url, { data: estimate }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.refreshPagination()
            } else {
              alert('오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async exportToExcel () {
        const url = '/manage/estimate/download'
        await this.$axios.get(url, {
          params: this.$route.query,
          responseType: 'blob',
        }).then(res => {
          if (res && res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            const today = new Date()
            const fileName = '견적서_' + today.getFullYear() + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + (today.getDay() > 9 ? today.getDay() : '0' + today.getDay()) + '.xlsx'
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }
        })
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD')
        } else {
          return date
        }
      },
      parseDateTime (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD HH:mm')
        } else {
          return date
        }
      },
      closeRegister (product) {
        product.showRegister = false
        this.refreshPagination()
      },
      closeDialog () {
        this.showRegister = false
        this.refreshPagination()
      },
      refreshPagination () {
        this.$emit('refresh')
      },
    },
  }
</script>

<style scoped>

</style>
