<template>
  <v-card class="pt-10 pt-md-3">
    <v-card-title>
      견적서 등록
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        v-model="company.idx"
        :items="companies"
        label="Company"
        no-data-text="검색된 업체가 없습니다."
      />
      <v-file-input
        v-model="file"
        label="Select File."
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </v-card-text>
    <v-card-actions class="pt-5 pb-3">
      <v-btn
        width="100%"
        @click="uploadEstimate"
      >
        견적서 등록
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ManageEstimateRegister',
    props: {
      companies: Array,
    },
    data () {
      return {
        company: {
          idx: null,
        },
        file: null,
      }
    },
    methods: {
      async uploadEstimate () {
        if (!this.company.idx) {
          alert('업체를 선택해주세요.')
          return
        }
        if (!this.file) {
          alert('업로드 할 견적서를 선택해주세요.')
          return
        }
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('companyIdx', this.company.idx)
        const url = '/manage/estimate'
        await this.$axios.post(url, formData).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('견적서를 업로드하였습니다.')
            this.close()
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      close () {
        this.$emit('close-dialog')
      },
    },
  }
</script>

<style scoped lang="scss">
  .carousel-image::v-deep {
    .v-responsive__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
</style>
