<template>
  <v-row class="mt-10">
    <v-col cols="6" md="3">
      <v-autocomplete
        v-if="parseCompany && parseCompany.length > 0"
        v-model="company.idx"
        :items="parseCompany"
        :search-input.sync="companyKeyword"
        label="Store"
        dense
        hide-details
        placeholder="업체 검색"
        no-data-text="검색된 업체가 없습니다."
      />
    </v-col>
    <v-col cols="6" md="3">
      <v-select
        v-model="searchField"
        :items="searchFields"
        label="Field"
        dense
        hide-details
        placeholder="조건"
      />
    </v-col>
    <v-col cols="6" md="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="startDate"
            prepend-inner-icon="mdi-calendar-check"
            label="Start"
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          locale="ko"
          type="date"
          @change="saveStartDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="6" md="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="endDate"
            prepend-inner-icon="mdi-calendar-check"
            label="End"
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          locale="ko"
          type="date"
          @change="saveEndDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        dense
        hide-details
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageEstimateSearch',
    props: [
      'companies',
    ],
    data () {
      return {
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        sdt: this.$route.query.sdt ? this.$parseDate(this.$route.query.sdt) : null,
        edt: this.$route.query.edt ? this.$parseDate(this.$route.query.edt) : null,
        searchField: this.$route.query.searchField ? this.$route.query.searchField : null,
        searchFields: [
          {
            text: '전체',
            value: null,
          },
          {
            text: '제품명',
            value: 'productName',
          },
          {
            text: '제품키워드',
            value: 'productKeyword',
          },
          {
            text: '스토어',
            value: 'productStore',
          },
        ],
        company: {
          idx: this.$route.query.company ? parseInt(this.$route.query.company | String) : null,
        },
        companyKeyword: null,
      }
    },
    computed: {
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const sdt = this.sdt.replaceAll('.', '-')
            const year = sdt.split('-')[0]
            const month = sdt.split('-')[1]
            const day = sdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const edt = this.edt.replaceAll('.', '-')
            const year = edt.split('-')[0]
            const month = edt.split('-')[1]
            const day = edt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
      parseCompany () {
        const array = []
        array.push({
          text: '전체',
          value: null,
        })
        for (const company of this.companies) {
          array.push(company)
        }
        return array
      },
    },
    methods: {
      search () {
        const data = {
          keyword: this.keyword ? this.keyword : '',
          searchField: this.searchField ? this.searchField : '',
          company: this.company.idx ? this.company.idx : '',
          sdt: this.getQueryDate(this.startDate, 'start') ? this.startDate : '',
          edt: this.getQueryDate(this.endDate, 'end') ? this.endDate : '',
        }
        this.$router.push({
          path: '/manage/estimate/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      getQueryDate (date, type) {
        if (date) {
          const year = date.split('-')[0]
          const month = date.split('-')[1]
          const day = date.split('-')[2]
          let d
          if (type === 'start') {
            d = new Date(year, month - 1, day, 0, 0, 0, 0)
          } else {
            d = new Date(year, month - 1, day, 23, 59, 59, 999)
          }
          return d.getTime()
        } else {
          return null
        }
      },
    },
  }
</script>

<style scoped>

</style>
