<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageEstimateHeader />
      <ManageEstimateSearch :companies="companies" @search="getPagination" />
      <ManageEstimateContent :companies="companies" :pagination="pagination" @refresh="getPagination" />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageEstimateHeader from '@/views/manage/estimate/Header'
  import ManageEstimateSearch from '@/views/manage/estimate/Search'
  import ManageEstimateContent from '@/views/manage/estimate/Content'

  export default {
    name: 'Product',
    components: { ManageEstimateContent, ManageEstimateSearch, ManageEstimateHeader },
    data () {
      return {
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: 10,
        companies: [],
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      company () {
        return this.$route.query.company
      },
      keyword () {
        return this.$route.query.keyword
      },
      searchField () {
        return this.$route.query.searchField
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
      this.getCompanies()
    },
    methods: {
      async getPagination (payload) {
        const url = '/manage/estimate'
        const params = payload || {}
        params.pageNum = this.pageNum
        params.pageDataSize = this.pageDataSize
        params.company = this.company ? this.company : null
        params.keyword = this.keyword ? this.keyword : null
        params.searchField = this.searchField ? this.searchField : null
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
